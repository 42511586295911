import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tab, Tabs, Form, Alert } from "react-bootstrap";
import {
  HandleChange, GetAllItems, CheckFormValidity, CheckFieldValidity,
  DefaultSelectValue
} from "../../utility/Form";
import CommonAddItemComp from "../common/Add";
import Select from 'react-select';
import { set, isEmpty, get, find, merge, sortBy } from "lodash";
import DatePicker from 'react-date-picker';
import ModalPopUp from "../common/modal/Modal";
import StatcTable from "../common/StaticTable";
import TextEditor from "../text-editor";
import CurrencyJson from "../../config/currency.json";
import ConfirmOnExitComp from "../common/ConfirmOnExit";
import { isBrowser } from "../common/ggfx-client/utility";
import CurrencyFormat from 'react-currency-format';

const AddFormControls = (props) => {
  //Default fields values
  const [DefaultFields, SetDefaultFields] = useState({
    price: null,
    completed_date: null,
    exchanged_date: null,
    bedroom: null,
    bathroom: null,
    reception: null,
    floorarea_min: null,
    floorarea_max: null,
    amenities: [],
    currency: {},
    latitude: null,
    longitude: null
  });

  //Select box validations
  const SelectBoxFieldDefaultErrors = {
    parent_building: {
      hasError: true,
      type: 'required'
    }
  };
  const [selectBoxFieldErrors, setSelectBoxFieldErrors] = useState({});

  const RentalStatusOptions = [
    DefaultSelectValue, { value: "Available", label: "Available" }, { value: "Unavailable", label: "Unavailable" }
  ];
  const SaleStatus = [
    DefaultSelectValue, { value: "Available", label: "Available" },
    { value: "Under Offer - Still Showing", label: "Under Offer - Still Showing" },
    { value: "Under Offer - Not Showing", label: "Under Offer - Not Showing" },
    { value: "Exchanged", label: "Exchanged" },
    { value: "Sold", label: "Sold" },
  ]
  const [dateValue, setDateValue] = useState();
  const [facilities, setFacilities] = useState([""]);
  const [highLightFeatures, setHighLightFeatures] = useState([""]);
  const [uploadedData, setUploadedData] = useState({});
  const [allUploadedItems, setAllUploadedItems] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [dateValue1, setDateValue1] = useState()
  const [dateValue2, setDateValue2] = useState()
  const [agentNotes, setAgentNotes] = useState([""]);
  const [currencyOption, setCurrencyOption] = useState();

  //Get building settings
  const GetSettings = async () => {
    try {
      const { data } = await GetAllItems('settings.getAll', { query: "_limit=-1" });
      setCurrencyOption(data?.results[0]?.currency)
      set(DefaultFields, 'currency', data?.results[0]?.currency);
    } catch (error) {
      console.log('AddFormControls GetSettings error', error);
    }
  };

  //Get buildings
  const GetBuildings = async () => {
    try {
      const { data } = await GetAllItems('buildings.getAll', { query: "deleteAttempt=0&_limit=-1&_sort=building_name:ASC" });
      let Buildings = data.results.map(building => {
        return {
          value: building.id, label: building.building_name,
          status: building.status,
          display_address: building.display_address,
          address_line1: building.address_line1,
          address_line2: building.address_line2,
          address_line3: building.address_line3,
          town: building.town,
          county: building.county,
          post_code: building.post_code,
          main_region: building?.main_region?.name,
          isDisabled: !building.published_at,
        }
      });
      Buildings.splice(0, 0, DefaultSelectValue);
      setBuildings(Buildings);
    } catch (error) {
      console.log('AddFormControls GetBuildings error', error);
    }
  };

  //Get facilities
  const GetFecilities = async () => {
    try {
      const { data } = await GetAllItems('facilities.getAll', { query: "_limit=-1&_sort=facility:ASC" });
      let Facilities = data.results.map(facility => { return { value: facility.id, label: facility.facility } });
      setFacilities(Facilities)
    } catch (error) {
      console.log('AddFormControls GetFecilities error', error);
    }
  };

  useEffect(() => {
    GetBuildings();
    GetSettings();
    GetFecilities();
  }, []);

  useEffect(() => {
    let UploadedItems = [...allUploadedItems].filter(item => !isEmpty(item));
    if (uploadedData.isUpdate || uploadedData.isDelete) {
      let itemsByField = [], itemsByOtherFields = [];
      UploadedItems.forEach(element => {
        if (element.field === uploadedData.field)
          return itemsByField.push(element);
        itemsByOtherFields.push(element);
      });
      if (uploadedData.isUpdate) {
        itemsByField = itemsByField.map((item, index) => {
          if (index === uploadedData.index) {
            const ItemMerged = { ...item, ...uploadedData };
            delete ItemMerged.isUpdate;
            return ItemMerged;
          }
          return item;
        });
      } else if (uploadedData.isDelete) {
        itemsByField = itemsByField.filter((item, index) => {
          if (index === uploadedData.index) {
            return false;
          }
          return item;
        });
      }
      UploadedItems = itemsByField.concat(itemsByOtherFields);
    } else if (!isEmpty(uploadedData) && !isEmpty(uploadedData.items)) {
      //concat 2 arrays
      UploadedItems = UploadedItems.concat(uploadedData.items);
    }
    UploadedItems = sortBy(UploadedItems, ['field', 'order']);
    set(DefaultFields, `${uploadedData.field}`, [...UploadedItems]
      .filter(item => (item.field === uploadedData.field)));
    setAllUploadedItems([...UploadedItems].filter(item => !isEmpty(item)));
  }, [uploadedData]);

  const [booked_dates, setbooked_dates] = useState([{ from: "", to: "" }]);
  const addbooked_dates = (index) => {
    setbooked_dates([...booked_dates, { from: "", to: "" }]);
  }

  const removebooked_dates = (index) => {
    let newbooked_dates = [...booked_dates]
    newbooked_dates.splice(index, 1);
    setbooked_dates(newbooked_dates);
  }

  const handlebooked_datesChange = (index, field, value) => {
    console.log(index, field, value)
    let newbooked_dates = [...booked_dates];
    newbooked_dates[index][field] = value;
    setbooked_dates(newbooked_dates);
  }

  // highlight features
  const addHighLightFeatures = (index) => {
    if (highLightFeatures[index]) {
      setHighLightFeatures([...highLightFeatures, ""]);
    } else {
      alert('Please fill!');
    }
  }
  const removeHighLightFeatures = (index) => {
    let newHighLightFeatures = [...highLightFeatures]
    newHighLightFeatures.splice(index, 1);
    setHighLightFeatures(newHighLightFeatures);
  }
  const handleHighLightFeaturesChange = (event, index) => {
    let newHighLightFeatures = [...highLightFeatures];
    newHighLightFeatures[index] = event.target.value;
    setHighLightFeatures(newHighLightFeatures);
  }
  const handleHighLightFeaturesKP = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault();
      addHighLightFeatures(index);
    }
  }

  // agent notes
  const addAgentNotes = (index) => {
    if (agentNotes[index]) {
      setAgentNotes([...agentNotes, ""]);
    } else {
      alert('Please fill!');
    }
  }
  const removeAgentNotes = (index) => {
    let newAgentNotes = [...agentNotes];
    newAgentNotes.splice(index, 1);
    setAgentNotes(newAgentNotes);
  }
  const handleAgentNotesChange = (event, index) => {
    let newAgentNotes = [...agentNotes];
    newAgentNotes[index] = event.target.value;
    setAgentNotes(newAgentNotes);
  }
  const handleAgentNotesKP = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault();
      addAgentNotes(index);
    }
  }

  const getUploadedData = (uploadedDataFromModal) => {
    setUploadedData(uploadedDataFromModal);
  }

  const [showModalImages, setShowModalImages] = useState(false);
  const [showModalBrochure, setShowModalBrochure] = useState(false);
  const showModalImagesFunc = () => {
    setShowModalImages(true);
  }
  const setShowModalImagesFunc = () => {
    setShowModalImages(false);
  }
  const showModalBrochureFunc = () => {
    setShowModalBrochure(true);
  }
  const setShowModalBrochureFunc = () => {
    setShowModalBrochure(false);
  }

  const [formError, setFormError] = useState({});
  const [isFormSaveTriggered, setIsFormSaveTriggered] = useState(false);
  const CheckFormFieldValidity = (event, isSelectBox = false) => {
    const FieldError = CheckFieldValidity(event, isSelectBox);
    setFormError({ ...formError, ...FieldError });
  }

  const HandleChangeFunc = (event) => {
    HandleChange(event);
    if (event?.target?.value) {
      if (event?.target?.name !== 'price')
        delete DefaultFields[event?.target?.name];
      if (event?.target?.name === 'latitude') 
        set(DefaultFields, 'latitude', (event?.target?.value || null));
      if (event?.target?.name === 'longitude') 
        set(DefaultFields, 'longitude', (event?.target?.value || null));
    } else {
      DefaultFields[event?.target?.name] = null;
    }
    CheckFormFieldValidity(event);
  }

  const DecodePriceValue = (priceObj) => {
    set(DefaultFields, 'price', priceObj.floatValue)
  }

  const FormSubmitFunc = async (event, additionalParams = {}) => {
    try {
      const Result = await props.HandleSubmitFunc(event, {
        formId: 'add-property-form',
        ...additionalParams,
        fields: { ...DefaultFields }
      });
      let selectBoxErrorObjTemp = {};
      if (isEmpty(selectBoxFieldErrors)) {
        selectBoxErrorObjTemp = { ...SelectBoxFieldDefaultErrors };
        setSelectBoxFieldErrors(selectBoxErrorObjTemp);
      } else if (find(selectBoxFieldErrors, function (sboxerror) {
        return (sboxerror.hasError)
      })) {
        selectBoxErrorObjTemp = selectBoxFieldErrors;
      }
      if (get(Result, 'formSubmit.hasError') || isEmpty(selectBoxErrorObjTemp)) {
        setFormError(Result.formSubmit.error);
      } else {
        setTabKey("0");
        setDateValue();
        setDateValue1();
        setDateValue2();
        setParentBuilding(DefaultSelectValue);
        setBuilding({});
        setBuildingType(DefaultSelectValue);
        setRentalType(DefaultSelectValue);
        setRentStatus(DefaultSelectValue);
        setSaleStatus(DefaultSelectValue);
        setFloor(DefaultSelectValue);
        setPriceQualifier(DefaultSelectValue);
        setViewing(DefaultSelectValue);
        setHolding(DefaultSelectValue);
        setAllUploadedItems([]);
        setCheckboxValues({});
        setAgentNotes([""]);
        setHighLightFeatures([""]);
        setRadioValues({});
      }
    } catch (error) {
      console.log('FormSubmitFunc error', error);
    }
  }

  const [tabKey, setTabKey] = useState("0")
  const MoveToTab = async (event, key, additionalParams = {}) => {
    setIsFormSaveTriggered(true);
    const ChildNodes = document.getElementsByClassName('tab-pane active')[0];
    const TabError = CheckFormValidity(ChildNodes.getElementsByClassName('form-control'),
      { isSubmit: false, isCustom: true });
    //Check any field has error
    let tabHasError = false;
    const TabErrorKeys = Object.keys(TabError),
      TabErrorKeysLength = TabErrorKeys.length;
    for (let index = 0; index < TabErrorKeysLength; index++) {
      tabHasError = TabError[TabErrorKeys[index]]?.hasError;
      if (tabHasError) {
        setFormError(TabError);
        break;
      }
    }
    //Check selectbox va;idations
    let selectBoxErrorObjTemp = {};
    if (tabKey == "0") {
      selectBoxErrorObjTemp = { ...selectBoxFieldErrors };
      if (isEmpty(selectBoxErrorObjTemp)) {
        selectBoxErrorObjTemp = { ...SelectBoxFieldDefaultErrors };
        setSelectBoxFieldErrors(selectBoxErrorObjTemp);
      } else if (!isEmpty(find(merge(SelectBoxFieldDefaultErrors, selectBoxFieldErrors), function (sboxerror) {
        return (sboxerror.hasError)
      }))) {
        selectBoxErrorObjTemp = merge(SelectBoxFieldDefaultErrors, selectBoxFieldErrors);
        setSelectBoxFieldErrors(selectBoxErrorObjTemp);
      }
    }
    if ((!tabHasError) && (isEmpty(find(selectBoxErrorObjTemp, function (serror) {
      return serror.hasError;
    })))) {
      if (additionalParams.isSubmit) {
        try {
          await FormSubmitFunc(event, additionalParams);
          setTabKey(key);
          setIsFormSaveTriggered(false);
        } catch (error) {
          console.log('additionalParams.isSave', error);
        }
      } else {
        setTabKey(key);
        setIsFormSaveTriggered(false);
      }
    }
    MoveToTopOfPage();
  };

  const SaveCurrentTab = (event) => {
    event.preventDefault();
    event.stopPropagation();
    MoveToTab(event, (parseInt(tabKey) + 1), {
      isSubmit: true,
      enableValidation: (tabKey === '2')
    });
  }

  const MoveToTopOfPage = () => {
    if (isBrowser()) {
      window.scrollTo(0, 0)
    }
  }

  const [parentBuilding, setParentBuilding] = useState(DefaultSelectValue);
  const [building, setBuilding] = useState({});
  const [buildingType, setBuildingType] = useState(DefaultSelectValue);
  const [rentalType, setRentalType] = useState(DefaultSelectValue);
  const [rentStatus, setRentStatus] = useState(DefaultSelectValue);
  const [saleStatus, setSaleStatus] = useState(DefaultSelectValue);
  const [floor, setFloor] = useState(DefaultSelectValue);
  const [priceQualifier, setPriceQualifier] = useState(DefaultSelectValue);
  const [viewing, setViewing] = useState(DefaultSelectValue);
  const [holding, setHolding] = useState(DefaultSelectValue);
  const [price, setPrice] = useState();

  const HandleMultiSelect = (event, name, copyInAddParam) => {
    if (copyInAddParam) {
      const SelectedOptions = event.map(option => option.value)
      set(DefaultFields, `${name}`, SelectedOptions);
    }
    if (SelectBoxFieldDefaultErrors[name]) {
      if (event?.value) {
        setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ [name]: { hasError: false } } });
      } else {
        setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ [name]: { hasError: true } } });
      }
    }
    switch (name) {
      case 'parent_building':
        const Building = find(buildings, function (building) {
          return building.value == event.value
        })
        setParentBuilding(event);
        setBuilding(Building || {});
        if (Building.status) {
          if (Building.status === 'For Sale') {
            set(DefaultFields, `search_type`, 'sales');
          } else if (Building.status === 'To Rent') {
            set(DefaultFields, `search_type`, 'lettings');
          }
        }
        if (event.value) {
          setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ parent_building: { hasError: false } } });
        }
        break;
      case 'building':
        setBuildingType(event);
        break;
      case 'rent.status':
        setRentStatus(event)
        break;
      case 'rent.tenure':
        setRentalType(event);
        break;
      case 'sale.status':
        setSaleStatus(event);
        break;
      case 'floor':
        setFloor(event);
        break;
      case 'price_qualifier':
        setPriceQualifier(event);
        break;
      case 'viewing':
        setViewing(event);
        break;
      case 'sale.tenure':
        setHolding(event);
        break;
      case 'currency':
        set(DefaultFields, 'currency', event);
        setCurrencyOption(event);
        break;
      default:
        break;
    }
  }

  const [checkboxValues, setCheckboxValues] = useState({});
  const HandleCheckBoxValue = (event) => {
    let checkBoxValueTemp = { [event.target.name]: event.target.checked };
    if ((event.target.name === 'is_enabled') && (!checkBoxValueTemp['is_enabled'])) {
      checkBoxValueTemp['is_show_on_website'] = false;
      checkBoxValueTemp['is_show_on_portal'] = false;
      checkBoxValueTemp['is_show_on_disposals_list'] = false;
    }
    setCheckboxValues({ ...checkboxValues, ...checkBoxValueTemp });
  }

  const [radioValues, setRadioValues] = useState({});
  const HandleRedioValue = (event) => {
    setRadioValues({ ...radioValues, ...{ [event.target.name]: event.target.value } });
  }

  const editorChange = (content, editor, fieldName) => { };

  return (
    <>
      <Form id="add-property-form" noValidate onSubmit={(event) => SaveCurrentTab(event)}>
        <section className="agent-tabs">
          {
            isFormSaveTriggered && ((find((selectBoxFieldErrors), function (sboxerror) {
              return (sboxerror.hasError)
            })) || (find(formError, function (error) {
              return (error.hasError)
            }))) && <Alert key="danger" variant="danger">
              Highlighted fields are required
            </Alert>
          }
          <Tabs
            defaultActiveKey={"0"}
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
            activeKey={tabKey}
            onSelect={(k) => MoveToTab({}, k)}
          >
            <Tab eventKey={"0"} title="Overview">
              <Row>
                <Col lg={12} className="d-flex justify-content-end">
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
              <input type="hidden" name="department" value="residential" />
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Building Name *</label>
                    <div className={`custom-selectbox-form selectbox-dropdown${isFormSaveTriggered && selectBoxFieldErrors["parent_building"]?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="parent_building"
                        options={buildings}
                        placeholder="Please select parent building"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={(event) => HandleMultiSelect(event, 'parent_building')}
                        required={true}
                        value={parentBuilding}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Building Status </label>
                    <input type="text" className="form-control" name="building_status"
                      value={building.status}
                      disabled={true}
                      placeholder="Selected building status" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Number / Name * </label>
                    <input type="text" className={`form-control${isFormSaveTriggered && formError?.building_name?.hasError ? ' pu-has-control-error' : ''}`}
                      name="building_name" placeholder="Enter number/name here"
                      required={true}
                      onChange={(event) => HandleChangeFunc(event)} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Property Type</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="building"
                        options={[
                          DefaultSelectValue,
                          { value: "Apartment", label: "Apartment" },
                          { value: "House", label: "House" },
                          { value: "Investment Block", label: "Investment Block" },
                        ]}
                        placeholder="Select property type"
                        classNamePrefix={"select-opt"}
                        isSearchable={false}
                        onChange={(event) => HandleMultiSelect(event, 'building')}
                        value={buildingType}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Address 1</label>
                    <input type="text" className="form-control"
                      defaultValue={building.address_line1}
                      name="address_line1"
                      placeholder="Enter address here"
                      onChange={(event) => HandleChangeFunc(event)} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Address 2</label>
                    <input type="text" className="form-control"
                      defaultValue={building.address_line2}
                      name="address_line2"
                      placeholder="Enter address here" />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Address 3</label>
                    <input type="text" className="form-control"
                      defaultValue={building.address_line3}
                      name="address_line3"
                      placeholder="Enter address here" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Town / City </label>
                    <input type="text" className="form-control"
                      defaultValue={building.town}
                      name="town"
                      placeholder="Enter town here"
                      onChange={(event) => HandleChangeFunc(event)}
                    />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>County </label>
                    <input type="text" className="form-control"
                      defaultValue={building.county}
                      name="county"
                      placeholder="Enter county here"
                      onChange={(event) => HandleChangeFunc(event)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Postcode</label>
                    <input type="text" className="form-control"
                      defaultValue={building.post_code}
                      name="post_code"
                      placeholder="Enter postcode here" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Latitude </label>
                    <input type="text" className="form-control"
                      defaultValue={building.latitude}
                      name="latitude"
                      placeholder="Enter latitude here"
                      onChange={(event) => HandleChangeFunc(event)}
                    />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Longitude </label>
                    <input type="text" className="form-control"
                      defaultValue={building.longitude}
                      name="longitude"
                      placeholder="Enter longitude here"
                      onChange={(event) => HandleChangeFunc(event)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <div className="form-check dashboard-checkbox me-5">
                        <input className="form-check-input" type="checkbox"
                          name="is_enabled"
                          value={true}
                          id="enabled"
                          onChange={event => HandleCheckBoxValue(event)}
                          checked={checkboxValues?.['is_enabled']} />
                        <label className="form-check-label" for="enabled">Enabled</label>
                      </div>
                    </li>
                    {
                      checkboxValues?.['is_enabled'] && <li className="list-inline-item">
                        <div className="form-check dashboard-checkbox me-5">
                          <input className="form-check-input" type="checkbox"
                            name="is_show_on_website"
                            value={true}
                            id="website"
                            onChange={event => HandleCheckBoxValue(event)}
                            checked={checkboxValues?.['is_show_on_website']} />
                          <label className="form-check-label" for="website">Show on Website</label>
                        </div>
                      </li>
                    }
                  </ul>
                </Col>
                {
                  checkboxValues?.['is_enabled'] && <Col lg={6} className="mb-4">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <div className="form-check dashboard-checkbox me-5">
                          <input className="form-check-input" type="checkbox" name="is_show_on_portal"
                            value={true}
                            id="portals"
                            onChange={event => HandleCheckBoxValue(event)}
                            checked={checkboxValues?.['is_show_on_portal']} />
                          <label className="form-check-label" for="portals">Show in Portals</label>
                        </div>
                      </li>
                      <li className="list-inline-item">
                        <div className="form-check dashboard-checkbox me-5">
                          <input className="form-check-input" type="checkbox" name="is_show_on_disposals_list"
                            value={true}
                            id="disposals"
                            onChange={event => HandleCheckBoxValue(event)}
                            checked={checkboxValues?.['is_show_on_disposals_list']} />
                          <label className="form-check-label" for="disposals">Show on Disposals List</label>
                        </div>
                      </li>
                    </ul>
                  </Col>
                }
              </Row>
              {
                (building.status === 'To Rent') && <Row>
                  <Col lg={6} className="mb-4">
                    <div className="form-wrapper">
                      <label>Rental status</label>
                      <div className="custom-selectbox-form selectbox-dropdown">
                        <Select
                          name="rent.status"
                          options={RentalStatusOptions}
                          placeholder="Please select rental status"
                          classNamePrefix={"select-opt"}
                          isSearchable={false}
                          onChange={(event) => HandleMultiSelect(event, 'rent.status')}
                          value={rentStatus}
                        // styles={customStyles}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} className="mb-4">
                    <div className="form-wrapper">
                      <label>Rental Type</label>
                      <div className="custom-selectbox-form selectbox-dropdown">
                        <Select
                          name="rent.tenure"
                          options={[
                            DefaultSelectValue,
                            { value: "Long Term", label: "Long Term" },
                            { value: "Short Term", label: "Short Term" },
                            { value: "Not Specified", label: "Not Specified" }
                          ]}
                          placeholder="Select rental type here"
                          classNamePrefix={"select-opt"}
                          isSearchable={false}
                          onChange={(event) => HandleMultiSelect(event, "rent.tenure")}
                          value={rentalType}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              }
              {
                (building.status === 'For Sale') && <Row>
                  <Col lg={6} className="mb-4">
                    <div className="form-wrapper">
                      <label>Sale status</label>
                      <div className="custom-selectbox-form selectbox-dropdown">
                        <Select
                          name="sale.status"
                          options={SaleStatus}
                          placeholder="Select sale status"
                          classNamePrefix={"select-opt"}
                          isSearchable={false}
                          onChange={(event) => HandleMultiSelect(event, 'sale.status')}
                          value={saleStatus}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              }
              {
                rentalType?.value === 'Short Term' && <div>
                  {/* <Row>                                                    
                                <Col lg={6} className="mb-4">
                                    <div className="form-wrapper">
                                        <label>Booked From</label>
                                        <div className="form-datepicker">
                                            <DatePicker
                                                format="dd-MM-yyyy"
                                                onChange={setDateValue1}
                                                value={dateValue1}
                                                name="booked_date_from"
                                                dayPlaceholder="dd"
                                                monthPlaceholder="mm"
                                                yearPlaceholder="yyyy"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} className="mb-4">
                                    <div className="form-wrapper">
                                        <label>Booked To</label>
                                        <div className="form-datepicker">
                                            <DatePicker
                                                format="dd-MM-yyyy"
                                                onChange={setDateValue2}
                                                value={dateValue2}
                                                name="booked_date_to"
                                                dayPlaceholder="dd"
                                                monthPlaceholder="mm"
                                                yearPlaceholder="yyyy"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row> */}
                  <Row>
                    <Col lg={12} className="mb-4">
                      <div className="form-wrapper">
                        {
                          booked_dates.map((element, i) => (
                            <div key={i}>
                              <Row>
                                <Col lg={5}>
                                  <div className="form-datepicker">
                                    <DatePicker
                                      format="dd-MM-yyyy"
                                      onChange={(value) => handlebooked_datesChange(i, 'from', value)}
                                      value={booked_dates[i].from}
                                      name={`booked_dates[${i}].from`}
                                      dayPlaceholder="dd"
                                      monthPlaceholder="mm"
                                      yearPlaceholder="yyyy"
                                    />
                                  </div>
                                </Col>
                                <Col lg={5}>
                                  <div className="form-datepicker">
                                    <DatePicker
                                      format="dd-MM-yyyy"
                                      onChange={(value) => handlebooked_datesChange(i, 'to', value)}
                                      value={booked_dates[i].to}
                                      name={`booked_dates[${i}].to`}
                                      dayPlaceholder="dd"
                                      monthPlaceholder="mm"
                                      yearPlaceholder="yyyy"
                                    />
                                  </div>
                                </Col>
                                <Col lg={1}>
                                  <div className="d-flex justify-content-between btn-add-delete">
                                    <button type="button" className="btn-none" onClick={() => addbooked_dates(i)}>
                                      <i className="icon icon-add-circle"></i>
                                    </button>
                                    {i > 0 &&
                                      <button type="button" className="btn-none" onClick={() => removebooked_dates(i)}>
                                        <i className="icon icon-delete-circle"></i>
                                      </button>
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          ))
                        }
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={9} className="mb-4">
                      <div className="form-wrapper">
                        <label>Guests</label>
                        <Row>
                          <Col lg={4}>
                            <input type="number" className="form-control" name="guests.adults"
                              min="0"
                              placeholder="Enter adults count here"
                            />
                          </Col>
                          <Col lg={4}>
                            <input type="number" className="form-control" name="guests.children"
                              min="0"
                              placeholder="Enter children count here"
                            />
                          </Col>
                          <Col lg={4}>
                            <input type="number" className="form-control" name="guests.infants"
                              min="0"
                              placeholder="Enter infants count here"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="form-check dashboard-checkbox me-3 mt-4">
                        <input className="form-check-input" type="checkbox"
                          value={true}
                          id="vat"
                          name="exclusive_of_vat"
                          onChange={(event) => HandleCheckBoxValue(event)}
                          checked={checkboxValues?.['exclusive_of_vat']} />
                        <label className="form-check-label" for="vat">Exclusive of Vat</label>
                      </div>
                    </Col>
                  </Row>
                </div>
              }
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Available from</label>
                    <div className="form-datepicker">
                      <DatePicker
                        format="dd-MM-yyyy"
                        name="available_from"
                        onChange={setDateValue}
                        value={dateValue}
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mb-4">
                  <div className="form-wrapper">
                    <label>Search Results Description</label>
                    <TextEditor name="description"
                      additionalParams={props.additionalParams}
                      editorChange={(content, editor) => editorChange(content, editor, 'description')} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Floor</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="floor"
                        options={[
                          DefaultSelectValue,
                          { value: "Lower ground floor", label: "Lower ground floor" },
                          { value: "Ground floor", label: "Ground floor" },
                          { value: "Floor 1", label: "Floor 1" },
                          { value: "Floor 2", label: "Floor 2" },
                          { value: "Floor 3", label: "Floor 3" },
                          { value: "Floor 4", label: "Floor 4" },
                          { value: "Floor 5", label: "Floor 5" }
                        ]}
                        placeholder="Select floor"
                        classNamePrefix={"select-opt"}
                        isSearchable={false}
                        onChange={(event) => HandleMultiSelect(event, 'floor')}
                        value={floor}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Square Footage *</label>
                    <input type="text" className={`form-control${isFormSaveTriggered && formError?.floorarea_min?.hasError ? ' pu-has-control-error' : ''}`}
                      name="floorarea_min"
                      placeholder="Enter square footage size here"
                      required={true}
                      onChange={(event) => HandleChangeFunc(event)} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>No. of Bedrooms *</label>
                    <input type="number" className={`form-control${isFormSaveTriggered && formError?.bedroom?.hasError ? ' pu-has-control-error' : ''}`}
                      name="bedroom"
                      min="0"
                      placeholder="Enter bedroom count here"
                      required={true}
                      onChange={(event) => HandleChangeFunc(event)} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>No. of Bathrooms *</label>
                    <input type="number" className={`form-control${isFormSaveTriggered && formError?.bathroom?.hasError ? ' pu-has-control-error' : ''}`}
                      name="bathroom"
                      min="0"
                      placeholder="Enter bathroom count here"
                      required={true}
                      onChange={(event) => HandleChangeFunc(event)} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <Row>
                      <Col lg={3}>
                        <label>Currency</label>
                        <div className="custom-selectbox-form selectbox-dropdown">
                          <Select
                            name="currency"
                            options={CurrencyJson}
                            placeholder="Please select currency"
                            classNamePrefix={"select-opt"}
                            isSearchable={false}
                            value={currencyOption}
                            onChange={(event => HandleMultiSelect(event, 'currency'))}
                          />
                        </div>
                      </Col>
                      <Col>
                        <label>Price *</label>
                        <CurrencyFormat displayType={'input'} thousandSeparator={true}
                          name="price"
                          value={price}
                          placeholder="Enter price"
                          className={`form-control${isFormSaveTriggered && formError?.price?.hasError ? ' pu-has-control-error' : ''}`}
                          onValueChange={(values) => {
                            DecodePriceValue(values)
                            setPrice(DecodePriceValue(values))
                          }}
                          onChange={(event) => HandleChangeFunc(event)}
                          required={priceQualifier?.value !== 'POA'}
                        />
                      </Col>
                      <Col>
                        <div className="custom-selectbox-form selectbox-dropdown">
                          <label></label>
                          <Select
                            name="price_qualifier"
                            options={[
                              DefaultSelectValue,
                              { value: "Per week", label: "Per week" },
                              { value: "Per month", label: "Per month" },
                              { value: "Per annum", label: "Per annum" }
                            ]}
                            placeholder="Select price qualifier"
                            classNamePrefix={"select-opt"}
                            isSearchable={false}
                            onChange={(event) => HandleMultiSelect(event, 'price_qualifier')}
                            value={priceQualifier}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Interior</label>
                    <ul className="list-inline">
                      <li className="list-inline-item me-3">
                        <div class="form-check dashboard-radio radio-caps">
                          <input class="form-check-input" type="radio"
                            name="interior"
                            id="furnished"
                            value="Available furnished or Semifurnished"
                            onChange={event => HandleRedioValue(event)}
                            checked={radioValues?.['interior'] === 'Available furnished or Semifurnished'} />
                          <label class="form-check-label" for="furnished">Available furnished or Semifurnished</label>
                        </div>
                      </li>
                      <li className="list-inline-item me-3">
                        <div class="form-check dashboard-radio radio-caps">
                          <input class="form-check-input" type="radio"
                            name="interior"
                            id="unfurnished"
                            value="Unfurnished"
                            onChange={event => HandleRedioValue(event)}
                            checked={radioValues?.['interior'] === 'Unfurnished'} />
                          <label class="form-check-label" for="unfurnished">Unfurnished</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <div className="form-check dashboard-checkbox me-5">
                        <input className="form-check-input" type="checkbox"
                          name="under_offer"
                          value={true}
                          onChange={event => HandleCheckBoxValue(event)}
                          id="offer"
                          checked={checkboxValues?.['under_offer']} />
                        <label className="form-check-label" for="offer">Under offer</label>
                      </div>
                    </li>
                    <li className="list-inline-item">
                      <div className="form-check dashboard-checkbox me-5">
                        <input className="form-check-input" type="checkbox"
                          name="is_featured_property"
                          value={true}
                          id="featured"
                          onChange={event => HandleCheckBoxValue(event)}
                          checked={checkboxValues?.['is_featured_property']} />
                        <label className="form-check-label" for="featured">Featured Property</label>
                      </div>
                    </li>
                    <li className="list-inline-item">
                      <div className="form-check dashboard-checkbox me-5">
                        <input className="form-check-input" type="checkbox"
                          name="is_es_featured_property"
                          value={true}
                          id="es"
                          onChange={event => HandleCheckBoxValue(event)}
                          checked={checkboxValues?.['is_es_featured_property']}
                        />
                        <label className="form-check-label" for="es">ES Featured Property</label>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Facilities</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="amenities"
                        isMulti={true}
                        options={facilities}
                        placeholder="Please select facilities"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={event => HandleMultiSelect(event, 'amenities', true)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10}>
                  <div className="form-wrapper">
                    <label>Highlight Features</label>
                  </div>
                  <div className="d-flex justify-content-start btn-wrapper">
                    <div className="mb-0 w-100">
                      {
                        highLightFeatures.map((element, i) => (
                          <div key={i} className="mb-4 d-flex justify-content-start">
                            <div className="form-wrapper w-100">
                              <input type="text" className={`form-control`} autoFocus={i > 0}
                                name={`special[${i}]`}
                                onChange={(event) => handleHighLightFeaturesChange(event, i)}
                                placeholder="Enter highlight features here"
                                onKeyPress={(event) => handleHighLightFeaturesKP(event, i)}
                                value={element} />
                            </div>
                            <div className="d-flex justify-content-between btn-add-delete">
                              <button type="button" className="btn-none" onClick={() => addHighLightFeatures(i)}>
                                <i className="icon icon-add-circle"></i>
                              </button>
                              {i > 0 &&
                                <button type="button" className="btn-none" onClick={() => removeHighLightFeatures(i)}>
                                  <i className="icon icon-delete-circle"></i>
                                </button>
                              }
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Viewing</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="viewing"
                        options={[
                          DefaultSelectValue,
                          { value: "By Master Key", label: "By Master Key" },
                          { value: "By Appointment", label: "By Appointment" },
                        ]}
                        placeholder="Please select"
                        classNamePrefix={"select-opt"}
                        isSearchable={false}
                        onChange={(event) => HandleMultiSelect(event, 'viewing')}
                        value={viewing}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10}>
                  <div className="form-wrapper">
                    <label>Agent Notes</label>
                  </div>
                  <div className="d-flex justify-content-start btn-wrapper">
                    {/* {agentNotes} */}
                    <div className="mb-0 w-100">
                      {
                        agentNotes.map((element, i) => (
                          <div key={i} className="mb-4 d-flex justify-content-start">
                            <div className="form-wrapper w-100">
                              <input type="text" className={`form-control`} autoFocus={i > 0}
                                name={`agent_notes[${i}]`}
                                onChange={(event) => handleAgentNotesChange(event, i)}
                                placeholder="Enter agent notes here"
                                onKeyPress={(event) => handleAgentNotesKP(event, i)}
                                value={element} />
                            </div>
                            <div className="d-flex justify-content-between btn-add-delete">
                              <button type="button" className="btn-none" onClick={() => addAgentNotes(i)}>
                                <i className="icon icon-add-circle"></i>
                              </button>
                              {i > 0 &&
                                <button type="button" className="btn-none" onClick={() => removeAgentNotes(i)}>
                                  <i className="icon icon-delete-circle"></i>
                                </button>
                              }
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Private Notes</label>
                    <input type="text" className="form-control" name="private_notes" placeholder="Enter your notes here" />
                  </div>
                </Col>
              </Row>
              {
                (building.status === 'For Sale') && (
                  <div>
                    <Row>
                      <Col lg={6} className="mb-4">
                        <div className="form-wrapper">
                          <label>Holding</label>
                          <div className="custom-selectbox-form selectbox-dropdown">
                            <Select
                              name="sale.tenure"
                              options={[
                                DefaultSelectValue,
                                { value: "Freehold", label: "Freehold" },
                                { value: "Leasehold", label: "Leasehold" },
                                { value: "Share of a freehold", label: "Share of a freehold" },
                              ]}
                              placeholder="Please select holding"
                              classNamePrefix={"select-opt"}
                              isSearchable={false}
                              onChange={(event) => HandleMultiSelect(event, 'sale.tenure')}
                              value={holding}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="mb-4">
                        <div className="form-wrapper">
                          <label>Holding Description</label>
                          <input type="text" className="form-control"
                            name="sale.description" placeholder="Enter holding description here" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} className="mb-4">
                        <div className="form-wrapper">
                          <label>Service Charge</label>
                          <input type="text" className="form-control" name="service_charge"
                            placeholder="Enter service charge here" />
                        </div>
                      </Col>
                      <Col lg={6} className="mb-4">
                        <div className="form-wrapper">
                          <label>Ground Rent</label>
                          <input type="text" className="form-control" name="ground_rent"
                            placeholder="Enter ground rent here" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )
              }
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Video Tour URL</label>
                    <input type="text" className="form-control" name="video_tour.url"
                      placeholder="Enter video url here" />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Virtual Tour URL</label>
                    <input type="text" className="form-control" name="virtual_tour.url"
                      placeholder="Enter virtual tour url here" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Bespoke Link URL</label>
                    <input type="text" className="form-control" name="bespoke_link.url"
                      placeholder="Enter bespoke link url here" />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Bespoke Link TEXT</label>
                    <input type="text" className="form-control" name="bespoke_link.text"
                      placeholder="Enter bespoke link text here" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "1")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"1"} title="Images">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p>Drag and drop assets - Property images. The order of the assets below is the order they will be shown on the website.</p>
                <div className="text-end">
                  <Button className="btn-primary-dashboard" onClick={() => showModalImagesFunc()}>Add</Button>
                </div>
              </div>
              {/* ModalPopUp */}
              {
                showModalImages && <ModalPopUp
                  modalShow={showModalImages}
                  getUploadedData={getUploadedData}
                  modalAction={'upload'}
                  setModalShowFunc={setShowModalImagesFunc}
                  uploadedDataFieldName="images"
                  parentPath="properties"
                  format_text=".png, .jpg, .jpeg, .webp"
                  accept_format={{
                    'image/jpeg': ['.jpeg', '.png', '.jpg', '.webp']
                  }}
                />
              }
              {/* UploadedItemsTable */}
              <StatcTable
                menu="Properties"
                parentPath="file"
                allItems={allUploadedItems.filter(item => (item.field === 'images'))}
                getUploadedData={getUploadedData}
              />
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "0")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "2")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"2"} title="Documents">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p>Drag and drop assets - Property brochures. The order of the assets below is the order they will be shown on the website.</p>
                <div className="text-end">
                  <Button className="btn-primary-dashboard" onClick={() => showModalBrochureFunc()}>Add</Button>
                </div>
              </div>
              {/* ModalPopUp */}
              {
                showModalBrochure && <ModalPopUp
                  modalShow={showModalBrochure}
                  getUploadedData={getUploadedData}
                  modalAction={'upload'}
                  setModalShowFunc={setShowModalBrochureFunc}
                  uploadedDataFieldName="documents"
                  parentPath="properties"
                  format_text=".pdf"
                  accept_format={{
                    'application/pdf': ['.pdf']
                  }}
                />
              }
              {/* UploadedItemsTable */}
              <StatcTable
                menu="Properties"
                parentPath="file"
                allItems={allUploadedItems.filter(item => (item.field === 'documents'))}
                getUploadedData={getUploadedData}
              />
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, 1)}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Submit</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </section>
      </Form>
    </>
  )
}

const AddProperty = (props) => {
  return <CommonAddItemComp PuFormControlsAdd={
    (HandleSubmitFunc) => {
      return (
        <>
          <AddFormControls
            {...props} HandleSubmitFunc={HandleSubmitFunc}
          />
        </>
      )
    }
  } {...props} DisableDefaultFormAction={true} />
}

export default AddProperty;
