import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import PropertiesAdd from "@components/properties/Add"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "properties.create"
}

const PropertiesAddPage = (props) => {
  return (
    <>
      <Seo title="Add Property" />
      <div className="main-wrapper">
        <Sidebar menuActive="properties"
          action="Add" />
        <PropertiesAdd
          menu="Add Property Details"
          parentPath="properties"
          pageType="Add"
          additionalParams={AdditionalParams} />
      </div>
    </>
  )
}

export default PropertiesAddPage;
